export const TechInfo = [
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/Javascript_rnv4wk.png',
        name: 'Javascript'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063084/Portfolio/React_dz4xsz.png',
        name: 'React Js'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/Redux_qlzhbd.png',
        name: 'Redux'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/HTML_qm4nrl.png',
        name: 'HTML'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/CSS_jkvqvh.png',
        name: 'CSS'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/MaterialUI_sieet9.png',
        name: 'Material UI'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/Express_sbxeua.png',
        name: 'Express'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/Sequielize_oav49p.png',
        name: 'Sequelize'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063083/Portfolio/Node_JS_jnorgw.png',
        name: 'Node Js'
    },
    {
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653063084/Portfolio/PostgreSQL_ksdxiy.png',
        name: 'PostgreSQL'
    }
]