import React, {useState, useRef} from "react";
import st from './Contact.module.css'
import emailjs from '@emailjs/browser';
import {Button, IconButton, TextField, Tooltip} from "@mui/material";
import {GitHub, LinkedIn, Mail, WhatsApp, Send} from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";

const Contact = () => {
    const form = useRef();
    const [Message, setMessage] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    })
    function handleMessageChange(event) {
        event.preventDefault();
        setMessage({
            ...Message,
            [event.target.name]: event.target.value
        })
    }
    function sendMail(event){
        event.preventDefault();
        emailjs.sendForm('service_9y785qn', 'template_09cds83', form.current, 'sxqoFsmkAjTIo94Pk')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
    return(
        <div id="Contact" className={st.container}>
            <div className={st.subNavbar}></div>
            <div className={st.mainDiv}>
                <div className={st.contactInfo}>
                    <p>Contact Information</p>
                    <div className={st.buttonsContainer}>
                        <Tooltip title="GitHub" arrow>
                            <IconButton href='https://github.com/tomasguixeras' target='_blank' color='secondary' >
                                <GitHub />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="LinkedIn" arrow>
                            <IconButton href='https://www.linkedin.com/in/tomasbohnguixeras/' target='_blank' color='secondary' >
                                <LinkedIn />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="WhatsApp" arrow>
                            <IconButton href='https://walink.co/b9cc01' target='_blank' color='secondary' >
                                <WhatsApp />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="E-Mail" arrow>
                            <IconButton href='mailto:tomasbohngs@gmail.com' color='secondary' >
                                <Mail />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <form className={st.formContainer} onSubmit={sendMail} ref={form}>
                    <div className={st.formSubcontainer}>
                        <TextField  label={"Name"} name="name" onChange={handleMessageChange} color='secondary'  sx={{ m:1 }} focused/>
                        <TextField label={"E-Mail"} name="email" onChange={handleMessageChange} color='secondary' sx={{ m:1 }} focused/>
                    </div>
                    <div className={st.formTop}>
                        <TextField label={"Subject"} name="subject" onChange={handleMessageChange} color='secondary' sx={{ m:1 }} focused/>
                        <TextField label={"Message"} multiline rows={7} name="message" onChange={handleMessageChange} color='secondary' sx={{ m:1 }} focused/>
                        <Button variant={"contained"} startIcon={<Send />} type="submit" color='secondary'>Send</Button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Contact
