import React from "react";
import styles from './Skills.module.css';
import TechCard from "../../components/TechCard/TechCard";
import {TechInfo} from "../../components/TechCard/TechInfo";

const Skills = () => {

    return(
        <div id="Skills" className={styles.container}>
            {/*<h3>Skills:</h3>*/}
            <h3>Tech Skills:</h3>
            <div className={styles.mainDiv}>
                <div className={styles.techSkills}>
                    {/*<h6>Tech Skills:</h6>*/}
                    <div className={styles.listTech}>
                    {
                        TechInfo.map((el, idx) => <TechCard key={idx} image={el.image} name={el.name} />)
                    }
                    </div>
                </div>
                {/*<div className={styles.softSkills}>*/}
                {/*    <h6>Soft Skills:</h6>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default Skills