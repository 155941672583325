
export const ProjectInfo = [
    {
        title: 'Henry Dog',
        date: 'April 2022',
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653403710/Portfolio/Screen_Shot_2022-05-24_at_11.47.28_pthkgy.png',
        description: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
        github: 'https://github.com/TomasBohnGs/PI-Dogs-App',
        deploy:'https://dogs-henry-app.vercel.app/'
    },
    {
        title: 'Markets Center',
        date: 'May 2022',
        image: 'https://res.cloudinary.com/tomyboohngs/image/upload/v1653403709/Portfolio/Screen_Shot_2022-05-24_at_11.47.51_yrpmt0.png',
        description: 'This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.',
        github: 'https://github.com/TomasBohnGs/markets-center-client',
        deploy:'https://markets-center.vercel.app/'
    }
]

// {
//     title: '',
//     date: '',
//     image: '',
//     description: '',
//     github: '',
//     deploy:''
// }