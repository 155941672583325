import React from "react";
import style from './Home.module.css'
import TomPng from '../../images/Hi.png'
import cv from '../../images/CV Bohn Guixeras.pdf'
import {Button} from "@mui/material";
import {animateScroll} from 'react-scroll'

const Home = () => {

    return(
        <div id="Home" className={style.container}>
            <div>
                <h5 className={style.hi}>Hi!</h5>
                <h3 className={style.myname}>I'm Tomás Bohn Guixeras!</h3>
                <h4 className={style.fullstack}>Fullstack Web Developer</h4>
                <div className={style.buttons}>
                    <Button variant={"contained"} color='secondary' >
                        <a 
                            href='https://drive.google.com/file/d/1IeHrHf7VQNsK3q9d6VTPGPx1rNOLu3eb/view?usp=sharing'
                            target='_blank'
                            rel='noreferrer'
                        > Download CV </a>
                    </Button>
                    <Button variant={"contained"} onClick={() => animateScroll.scrollToBottom()} color='secondary'>Contact Me</Button>
                </div>
            </div>
            <div className={style.imageBg}>
                <img className={style.image} src={TomPng} alt={''} />
            </div>
        </div>
    )
}

export default Home