import React from 'react';
import {Card, CardHeader, CardMedia, CardContent, CardActions, Typography, Button,} from '@mui/material'
import {ExitToApp, GitHub} from '@mui/icons-material';

import { ProjectInfo } from './ProjectInfo.js'

export default function Card2() {
    return (
        <>
        {
            ProjectInfo.map(element => (
                <Card  sx={{ backgroundColor: '#f9dc5c', maxWidth: 345, m:2 }}>
                    <CardHeader
                        title={element.title}
                        subheader={element.date}
                        
                    />
                    <CardMedia
                        component="img"
                        height="auto"
                        // height="194"
                        image={element.image}
                        alt=""
                    />
                    <CardContent>
                        <Typography variant="body2" color="text.secondary">
                            {element.description}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button startIcon={<GitHub />} href={element.github} target="_blank">Code</Button>
                        <Button startIcon={<ExitToApp />} href={element.deploy} target="_blank">Deploy</Button>
                    </CardActions>
                </Card>
            ))
        }
        </>
    );
}