import React from "react";
import styles from './About.module.css';
import Image from '../../images/Home.png'

const About = () => {
    return(
        <div id="About" className={styles.container}>
            <h3>About:</h3>
            <div className={styles.mainDiv}>
                <div className={styles.image}>
                    <img src={Image} alt={''} />
                </div>
                <div className={styles.text}>
                    <p>Hello! I'm Tomás Bohn Guixeras, Fullstack Web Developer from Buenos Aires, Argentina. 🇦🇷</p>
                    <p>Thanks to my studies and working experiences I strengthened my sense of responsibility, compromise and team work, fundamental pillars to develop the necessary constancy and dedication for these profession.</p>
                    <p>I enjoy the challange of developing new projects every day which allow me to continue learning and completing me as a professional.</p>
                </div>
            </div>
        </div>
    )
}

export default About