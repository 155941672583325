import React from "react";
import styles from "./Navbar.module.css";
import Logo from '../../images/2.png'
import { AppBar, Container, Box, Toolbar} from '@mui/material'
import { animateScroll, Link } from 'react-scroll'

const Navbar = () => {

    return(
        <AppBar position={"fixed"} className={styles.navbar} >
            <Container maxWidth="xl" >
                <Toolbar disableGutters sx={{ justifyContent: 'space-between', maxWidth: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div className={styles.logoCont} onClick={() => animateScroll.scrollToTop()}>
                        <img src={Logo} alt={''}/>
                    </div>
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <div className={styles.linksCont}> 
                                <Link activeClass={styles.selectedLink} className={styles.links} to="Home" spy={true} smooth={true} duration={500} >Home</Link>
                                <Link activeClass={styles.selectedLink} className={styles.links} to="About" spy={true} smooth={true} duration={500} >About</Link>
                                <Link activeClass={styles.selectedLink} className={styles.links} to="Skills" spy={true} smooth={true} duration={500} >Skills</Link>
                                <Link activeClass={styles.selectedLink} className={styles.links} to="Work" spy={true} smooth={true} duration={500} >Work</Link>
                                <Link activeClass={styles.selectedLink} className={styles.links} to="Contact" spy={true} smooth={true} duration={500} >Contact</Link>
                            </div>
                        </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Navbar