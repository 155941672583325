import React from "react";
import styles from './Footer.module.css'

const Footer = () => {
    return(
        <div className={styles.mainDiv}>
            <p className={styles.firstP}>2023 - Tomás Bohn Guixeras</p>
        </div>
    )
}

export default Footer