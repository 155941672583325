import React from 'react';
import Navbar from './components/Navbar/Navbar.jsx'
import Home from './pages/Home/Home.jsx'
import About from './pages/About/About.jsx'
import Contact from './pages/Contact/Contact.jsx'
import Skills from './pages/Skills/Skills.jsx'
import Work from './pages/Work/Work.jsx'
import styles from './App.module.css';

function App() {
  return (
    <div className={styles.App}>
        <Navbar />
        <body className={styles.BodyContainer}>
          <Home />
          <About />
          <Skills />
          <Work />
          <Contact />
        </body>
    </div>
  );
}

export default App;
