import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#47829a',
        },
        secondary: {
          main: '#f9dc5c',
        },
        // background: {
        //   default: '#47829a',
        // },
        info: {
          main: '#2ad833',
          contrastText: "#fff"
        }
      }
});