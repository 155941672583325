import React from "react";
import styles from "./TechCard.module.css";
// import Demo from '../../images/TechIcons/Javascript.png'

const TechCard = ({image, name}) => {

    return(
        <div className={styles.cardContainer}>
            <img src={image} alt={''} />
            <h6>{name}</h6>
        </div>
    )
}

export default TechCard