import React from "react";
import styles from '../../App.module.css';
import style from './Work.module.css'

import Card2 from '../../components/ProjectCard/Card.jsx'

const Work = () => {
    return(
        <div id="Work" className={style.container}>
            <h3>My Projects</h3>
            <div className={style.mainDiv}>
                <Card2 />
            </div>
        </div>
    )
}

export default Work